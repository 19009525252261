@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn-navigation {
        @apply hover:bg-sidebar-highlight-dark hover:text-primary-color-dark text-secondary-color-dark flex gap-1.5 rounded-md p-2 text-sm m-1 outline-none border-base-dark border;
    }

    .btn-navigation-active {
        @apply bg-sidebar-highlight-dark hover:text-primary-color-dark text-secondary-color-dark flex gap-1.5 rounded-md p-2 text-sm m-1 outline-none border-base-dark border;
    }

    .tag-navigation {
        @apply hover:bg-sidebar-highlight-dark hover:text-primary-color-dark text-secondary-color-dark flex gap-1.5 rounded-md p-1 pl-2 text-sm m-1 outline-none;
    }

    .tag-navigation-active {
        @apply bg-sidebar-highlight-dark hover:text-primary-color-dark text-secondary-color-dark flex gap-1.5 rounded-md p-1 pl-2 text-sm m-1 outline-none;
    }
}

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800&display=swap');

@layer base {
    html {
        font-family: 'M PLUS Rounded 1c', system-ui, sans-serif;
    }
}

html {
    /* background-color: #202432; */

    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;

    user-select: none;
    height: 100%;
}

body * {
    cursor: default;
    height: 100%;
}

input {
    cursor: text;
}

body {
    margin: 0;
    padding: 0;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

html,
body {
    overscroll-behavior-y: none;
    position: fixed;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

a,
button {
    cursor: default;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.ResizeHandleInner[data-is-dragging='true'] {
    --background-color: '#ff0000';
}

.editable-content:empty::before {
    content: attr(data-placeholder);
    color: rgb(96 165 250);
}

.Toastify__toast-container--bottom-right {
    height: auto;
}

#logo-main {
    width: 200px;
    height: auto;
}

#logo-center {
    width: 120px;
    height: auto;
}

#logo-header {
    width: 80px;
    height: auto;
}

#logo-footer {
    width: 50px;
    height: auto;
}